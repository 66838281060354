
import { defineComponent } from "vue";
import { PostImage } from "@/types";
export default defineComponent({
  props: {
    image: {
      type: Object as () => PostImage,
      required: true
    }
  }
});
