
import { defineComponent } from "vue";
import Mixin from "./mixins";

export default defineComponent({
  data(): { agreed: boolean } {
    return {
      agreed: false
    };
  },
  mixins: [Mixin],
  methods: {
    enableGA1() {
      this.enableGA();
      this.refresh();
    },
    disableGA1() {
      this.disableGA();
      this.refresh();
    },
    refresh() {
      this.agreed = this.getAgreedState() === "true";
    }
  },
  mounted() {
    this.refresh();
  }
});
