
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";
export default defineComponent({
  components: { FontAwesomeIcon },
  data() {
    return {
      code: ""
    };
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.code);
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.code = (this.$refs.mySlot as any).textContent;
  }
});
