
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import PostImageComponent from "./image.vue";
import hljs from "highlight.js";
import { Post } from "@/types";
export default defineComponent({
  components: {
    PostImageComponent
  },
  setup(props) {
    useMeta({
      title: props.post.title,
      description: props.post.description
    });
  },
  props: {
    post: {
      type: Object as () => Post,
      required: true
    }
  },
  computed: {
    ghLink(): string | null {
      if (!this.post.ghFolder) {
        return null;
      }
      return `https://github.com/mbitzos/devblog-code-examples/tree/main/${this.post.ghFolder}`;
    }
  },
  mounted() {
    hljs.highlightAll();
  }
});
