
import { defineComponent } from "@vue/runtime-core";
import Posts from "../posts/index";
import { Post } from "@/types";
const posts = [...Posts];
posts.reverse();
export default defineComponent({
  data() {
    return {
      articles: posts
    };
  },
  methods: {
    isSelected(post: Post) {
      return post.key == this.$route.name;
    }
  }
});
