// Template file for generation
import { Post } from "@/types";
import { default as post0 } from "./generated-posts/introductions.meta";
import { default as post1 } from "./generated-posts/fsm-player-controllers.meta";
import { default as post2 } from "./generated-posts/programmatic-animation-using-coroutines.meta";
import { default as post3 } from "./generated-posts/debug-settings-menu.meta";
import { default as post4 } from "./generated-posts/implementing-stairs-in-2d-top-down-games.meta";
import { default as post5 } from "./generated-posts/demystifying-game-persistence.meta";

const posts: Array<Post> = [post0, post1, post2, post3, post4, post5];
export default posts;
