
import { useMeta, useActiveMeta } from "vue-meta";
import { defineComponent } from "vue";
import PrivacyModal from "./views/privacy/privacy-modal.vue";
export default defineComponent({
  components: {
    PrivacyModal
  },
  setup() {
    useMeta({
      description:
        "Welcome to my website where you can get my contact info and follow my game devblog.",
      title: "Michael Bitzos",
      ...useActiveMeta()
    });
  },
  methods: {
    hello() {
      console.log("hello and welcome to my website.");
      console.log(
        "currently working on building my gamedev blog, check it out: michaelbitzos.com/devblog"
      );
      console.log(
        "This was made with Vue3 and TS. I don't know why I did this but here we are."
      );
    }
  },
  created() {
    this.hello();
  }
});
