
import { defineComponent } from "vue";
import Mixins from "./mixins";
export default defineComponent({
  components: {},
  mixins: [Mixins],
  data() {
    return {
      show: true
    };
  },
  computed: {
    canShow(): boolean {
      return this.show && this.$route.name !== "privacy";
    }
  },
  methods: {
    accept() {
      this.enableGA();
      this.show = false;
    },
    reject() {
      this.disableGA();
      this.show = false;
    },
    hide() {
      this.show = false;
    }
  },
  mounted() {
    // only show if they haven't already answered
    this.show = this.getAgreedState() === null;
  }
});
