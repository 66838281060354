
import sidebar from "./components/sidebar.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";
export default defineComponent({
  components: { sidebar, FontAwesomeIcon },
  data() {
    return {
      open: false
    };
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    }
  }
});
